import { Result, failure, success } from '../../entity/Result'
import { User } from '../../entity/User'
import { V1UserInfo } from '../../entity/V1UserInfo'
import { CompanyRepository } from '../repository/CompanyRepository'
import { MyRepository } from '../repository/MyRepository'
import { UserRepository } from '../repository/UserRepository'
import {
  GetMyUserError,
  GetMyV1UserInfoError,
  GetRedirectUrlError,
  TopPageUrls,
  UserUsecase,
} from './UserUsecase'

export class UserUsecaseImpl implements UserUsecase {
  constructor(
    private userRepository: UserRepository,
    private companyRepository: CompanyRepository,
    private myRepository: MyRepository
  ) {}

  async getRedirectUrl(
    userId: number
  ): Promise<Result<TopPageUrls, GetRedirectUrlError>> {
    const userResponse = await this.userRepository.apiv2.user[':user_id'].$get({
      param: { user_id: userId.toString() },
    })
    const userJson = await userResponse.json()
    if ('message' in userJson) {
      if (userJson.message === 'Forbidden') {
        return failure('Forbidden')
      } else {
        return failure('UnkownError')
      }
    } else {
      const companyResponse = await this.companyRepository.apiv2.company[
        ':company_id'
      ].$get({
        param: { company_id: userJson.companyId.toString() },
      })
      const companyJson = await companyResponse.json()
      if ('message' in companyJson) {
        if (companyJson.message === 'Forbidden') {
          return failure('Forbidden')
        } else {
          return failure('UnkownError')
        }
      } else {
        if (companyJson.companyType === 'airline') {
          return success('/airlineTop')
        } else if (companyJson.companyType === 'forwarder') {
          return success('/fwdrTop')
        } else if (companyJson.companyType === 'shipper') {
          return success('/shipperTop')
        } else if (companyJson.companyType === 'admin') {
          return success('/jhTop')
        } else {
          return failure('UnknownCompanyType')
        }
      }
    }
  }

  async getMyV1UserInfo(): Promise<
    Result<{ userInfo: V1UserInfo; token: string }, GetMyV1UserInfoError>
  > {
    const response = await this.myRepository.apiv2.my.user['v1-info'].$get()
    const json = await response.json()
    if ('userInfo' in json) {
      return success(json)
    } else {
      return failure('Unauthorized')
    }
  }
  async getMyUser(): Promise<Result<User, GetMyUserError>> {
    const response = await this.myRepository.apiv2.my.user.$get()
    const json = await response.json()
    if ('userId' in json) {
      return success(json)
    } else {
      return failure('GetMyUserError')
    }
  }
}
