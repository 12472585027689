import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { SignIn } from '../page/SignIn'
import './index.css'
import '../i18n.ts'
import { hc } from 'hono/client'
import { AuthzUsecaseImpl } from '../usecase/AuthzUsecaseImpl.ts'
import { Authorization, Company, My, User } from '../../server'
import { UserUsecaseImpl } from '../usecase/UserUsecaseImpl'

const root = ReactDOM.createRoot(document.getElementById('root')!)

const apiEndpoint = import.meta.env.VITE_API_V2_ENDPOINT
if (!apiEndpoint) {
  throw new Error('VITE_API_V2_ENDPOINT is not set')
}

const authnUsecase = new AuthzUsecaseImpl(hc<Authorization>(apiEndpoint))
const userUsecase = new UserUsecaseImpl(
  hc<User>(apiEndpoint),
  hc<Company>(apiEndpoint),
  hc<My>(apiEndpoint)
)

root.render(
  <React.StrictMode>
    <SignIn authnUsecase={authnUsecase} userUsecase={userUsecase} />
  </React.StrictMode>
)
