import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import { AuthzUsecase } from '../usecase/AuthzUsecase'
import { UserUsecase } from '../usecase/UserUsecase'
import { CustomTextInput } from '../common/component/CustomInput'

type Props = {
  authnUsecase: AuthzUsecase
  userUsecase: UserUsecase
}

const SignInForm: React.FC<Props> = ({ authnUsecase, userUsecase }: Props) => {
  const { t } = useTranslation()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [logInErrorMessage, setLogInErrorMessage] = useState<string>('')

  // ログイン済みかどうかの判定
  useEffect(() => {
    if (
      document.cookie.includes('access_token') &&
      // V1画面向けの判定
      localStorage.getItem('token')
    ) {
      setIsLoggedIn(true)
    }
  }, [])

  // isLoggedInがtrueになったらリダイレクト
  useEffect(() => {
    ;(async () => {
      if (isLoggedIn) {
        console.log('ログイン済みと判断')
        const userResult = await userUsecase.getMyUser()
        if (userResult.type === 'Failure') {
          setIsLoggedIn(false)
          setLogInErrorMessage(t('SignIn_ユーザー情報が取得できませんでした'))
          return
        }
        const redirectUrlResult = await userUsecase.getRedirectUrl(
          userResult.value.userId
        )
        if (redirectUrlResult.type === 'Success') {
          // V1画面対応 userInfoを取得してlocalStorageに保存
          const userInfoResult = await userUsecase.getMyV1UserInfo()
          if (userInfoResult.type === 'Success') {
            localStorage.setItem(
              'userInfo',
              JSON.stringify(userInfoResult.value.userInfo)
            )

            localStorage.setItem('token', userInfoResult.value.token)
          } else {
            setLogInErrorMessage(t('SignIn_ユーザー情報が取得できませんでした'))
          }
          // リダイレクトする
          window.location.href = redirectUrlResult.value
        } else {
          switch (redirectUrlResult.error) {
            case 'Forbidden':
            case 'UnknownCompanyType':
            case 'UnkownError':
              setIsLoggedIn(false)
              setLogInErrorMessage(
                t('SignIn_ユーザー種別が取得できませんでした')
              )
              break
          }
        }
      }
    })()
  }, [isLoggedIn])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const handleLogin = async () => {
      const result = await authnUsecase.signIn(username, password)
      if (result.type === 'Failure') {
        setIsLoggedIn(false)
        switch (result.error) {
          case 'InvalidEmailOrPassword':
            setLogInErrorMessage(
              t('SignIn_メールアドレスまたはパスワードが間違っています')
            )
            break
        }
      }
      setIsLoggedIn(true)
    }
    handleLogin()
    event.preventDefault()
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full flex flex-col justify-center items-center"
    >
      <CustomTextInput
        type="email"
        value={username}
        placeholder={t('SignIn_Email')}
        onChange={(e) => setUsername(e.target.value)}
        labelElement={
          <span className="text-gray-700 w-7 h-7 i-material-symbols-mail-outline"></span>
        }
      />
      <CustomTextInput
        type="password"
        value={password}
        placeholder={t('SignIn_パスワード')}
        onChange={(e) => setPassword(e.target.value)}
        labelElement={
          <span className="text-gray-700 w-7 h-7 i-material-symbols-key-outline"></span>
        }
      />
      {logInErrorMessage && (
        <div>
          <p className="text-red-500 text-xs font-bold italic">
            {logInErrorMessage}
          </p>
        </div>
      )}
      <div className="flex items-center justify-between">
        <button
          type="submit"
          className="bg-white hover:bg-gray-300 text-black font-bold py-2 px-4 rounded-full disabled:opacity-50 focus:outline-none focus:shadow-outline"
          disabled={!username || !password}
        >
          {t('SignIn_ログイン')}
        </button>
      </div>
    </form>
  )
}

const LanguageChanger: React.FC = () => {
  const { i18n } = useTranslation()
  return (
    <div className="absolute top-0 right-0 m-4">
      <Menu>
        <MenuButton>
          <div className="flex rounded bg-white opacity-50 px-2 py-2">
            <span className="w-7 h-7 opacity-100 i-twemoji-globe-with-meridians"></span>
            <span className="w-7 h-7 opacity-100 i-material-symbols-keyboard-arrow-down"></span>
          </div>
        </MenuButton>
        <Transition
          enter="transition ease-out duration-75"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <MenuItems
            anchor="bottom end"
            className="flex flex-col opacity-60 rounded bg-white"
          >
            <MenuItem>
              <button
                onClick={() => i18n.changeLanguage('en')}
                className="text-black hover:text-gray-300 border border-gray-300 px-2 py-1"
              >
                <span className="mr-2 w-7 h-7 i-twemoji-flag-us-outlying-islands"></span>
                <span className="align-top">English</span>
              </button>
            </MenuItem>
            <MenuItem>
              <button
                onClick={() => i18n.changeLanguage('ja')}
                className="text-black hover:text-gray-300 border border-gray-300 px-2 py-1"
              >
                <span className="mr-2 w-7 h-7 i-twemoji-flag-japan"></span>
                <span className="align-top">日本語</span>
              </button>
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  )
}

export const SignIn: React.FC<Props> = ({
  authnUsecase,
  userUsecase,
}: Props) => {
  const { t } = useTranslation()
  return (
    <div className="flex justify-center items-center h-screen bg-gray-10 bg-center bg-[url('/images/background_airplane.png')]">
      <LanguageChanger />
      <div className="w-full flex flex-col justify-center items-center">
        <img
          className="max-w-full h-full mb-11 mt-24"
          src="/images/CargoLabo_logo_white.svg"
        />
        <div className="max-w-md w-full">
          <SignInForm authnUsecase={authnUsecase} userUsecase={userUsecase} />
          <div className="flex flex-col">
            <div>
              <a
                href="/lightFwdrRegisterNewAccount"
                className="text-white underline"
              >
                Register Forwarder
              </a>
            </div>
            <div>
              <a
                href="/lightShipperRegisterNewAccount"
                className="text-white underline"
              >
                Register Shipper
              </a>
            </div>
            <div>
              <a
                href="/lightAirlineRegisterNewAccount"
                className="text-white underline"
              >
                Register Airline
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
